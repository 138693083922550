import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import Flip from "react-reveal/Flip";

function ExperienceJourney() {
  return (
    <div className="mt-5">
      <div>
        <Link className="btn btn-primary m-1" to="/educationjourney">
          Educational Journey
        </Link>
        <Link className="btn btn-primary m-1" to="/experiencejourney">
          Experience Journey
        </Link>
        <Link className="btn btn-primary m-1" to="/technicaljourney">
          Technical Journey
        </Link>
        <Link className="btn btn-primary m-1" to="/projectjourney">
          Project Journey
        </Link>
      </div>
      <div className="mt-5">
        <Flip top cascade>
          <h1>Experience Journey</h1>
        </Flip>
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(78 22 112)" }}
          date="2015 - 2023"
          iconStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          icon={<PersonIcon />}
        >
          <h4 className="vertical-timeline-element-title">PT. Bapintri</h4>
          <h6 className="vertical-timeline-element-subtitle mt-2">
            IT Support
          </h6>
          <p>
            • Menyediakan dukungan teknis kepada pengguna komputer dan sistem
            IT.
          </p>
          <p>• Menyelesaikan masalah perangkat keras dan perangkat lunak.</p>
          <p>
            • memberikan panduan kepada pengguna untuk mengatasi kendala yang
            mereka hadapi.
          </p>
          <p>
            • Mengelola jaringan, melakukan instalasi dan konfigurasi perangkat,
            serta memastikan keamanan sistem.
          </p>
          <p>
            • komunikasi yang baik untuk membantu pengguna dengan berbagai
            tingkat pemahaman teknis.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          contentArrowStyle={{ borderLeft: "7px solid  rgb(78 22 112)" }}
          date="2023 - 2024"
          iconStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          icon={<PersonIcon />}
        >
          <h4 className="vertical-timeline-element-title">Makers Institute</h4>
          <h6 className="vertical-timeline-element-subtitle mt-2">
            Full Stack Developer
          </h6>
          <p>
            •Pengembangan Front-End:
            <br />
            Merancang dan mengimplementasikan antarmuka pengguna yang responsif
            dan interaktif menggunakan HTML, CSS, dan JavaScript.
          </p>
          <p>
            •Pengembangan Back-End:
            <br />
            Menggunakan framework back-end seperti Flask atau Node.js untuk
            mengembangkan aplikasi yang scalable dan maintainable.
          </p>
          <p>
            •Pengembangan DataBase:
            <br />
            Menggunakan database seperti PostgreSQL untuk menyimpan dan mengolah
            data
          </p>
          <p>
            •Lainnya:
            <br />
            Berkolaborasi dengan tim untuk menghasilkan project yang berjalan
            agar sesuai dengan yang diharapkan
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          contentArrowStyle={{ borderLeft: "7px solid  rgb(78 22 112)" }}
          date="2024 - 2025"
          iconStyle={{ background: "rgb(78 22 112)", color: "#fbd9ad" }}
          icon={<PersonIcon />}
        >
          <h4 className="vertical-timeline-element-title">PT Navcore Nextology</h4>
          <h6 className="vertical-timeline-element-subtitle mt-2">
            Front End Developer
          </h6>
          <p>
            Merancang dan mengimplementasikan antarmuka pengguna yang responsif
            dan interaktif menggunakan React, Material UI, dan CSS.
          </p>
          <p>
            Mengintegrasikan dari front end ke back end menggunakan API yang telah dibuat oleh backend.
          </p>
          <p>
            Membuat validasi form menggunakan react hook form agar data yang diinputkan valid.
          </p>
          <p>
            Membuat redux untuk state management agar data yang diinputkan dapat diakses oleh seluruh halaman
          </p>
          <p>
            Mengimplementasikan low code untuk membuat halaman dengan cepat serta mempermudah pengelolaan dan maintenance halaman
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "#fbd9ad", color: "rgb(78 22 112)" }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
}

export default ExperienceJourney;
